import Img1 from "../../assets/images/kishan2.jpeg";
import Container from "@mui/material/Container";
import ImgLogo from "../../assets/images/kishan_logo.svg";
import Grid from "@mui/material/Grid";
import classes from "./OTP.module.css";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import axios from "axios"; // Import Axios
import { Carousel } from "react-responsive-carousel";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

export const OTP = (props) => {
  const [expiryTime, setExpiryTime] = useState(300); // Initial time in seconds (e.g., 90 seconds)
  const [countdownActive, setCountdownActive] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem("currentKey") != 2) {
      switch (sessionStorage.getItem("currentKey")) {
        case "1":
          navigate("/");
          break;
        case "2":
          navigate("/otp");
          break;
        case "3":
          navigate("/register");
          break;
        case "4":
          navigate("/thankyou");
          break;
        default:
          navigate("/");
      }
    }
    const interval = setInterval(() => {
      if (expiryTime > 0) {
        setExpiryTime((prevTime) => prevTime - 1);
      } else {
        setCountdownActive(false); // Countdown finished, set to false
        clearInterval(interval); // Stop the interval
        // Display a toast message when OTP expires
        toast.error("OTP has expired. Please try again.");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryTime]);

  // Convert seconds to minutes and seconds
  const minutes = Math.floor(expiryTime / 60);
  const seconds = expiryTime % 60;

  const [otp, setOtp] = useState("");
  const [phoneError, setPhoneError] = useState("");

  // Get the location object
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  // // Get the mobileNumber query parameter
  // const mobileNumber = queryParams.get("mobileNumber");
  const mobileNumber = sessionStorage.getItem("mobileNumber");

  const navigate = useNavigate();

  const handleSendOTPClick = async () => {
    if (otp.length !== 4) {
      toast.error("Please enter a 4-digit OTP.");
      setOtp(""); // Clear the OTP field
    } else {
      setPhoneError("");
  
      // Prepare the request payload
      const payload = {
        mobileNumber: mobileNumber,
        otp: otp,
      };
  
      try {
        // Make a POST request to the API using Axios
        const response = await axios.post(
          process.env.REACT_APP_API_DOMAIN + "/api/verify-otp",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.status === 200) {
          // Handle a successful response (e.g., redirect or show a success message)
  
          sessionStorage.setItem("sessionId", response.data.sessionId);
          sessionStorage.setItem("currentKey", 3);
          navigate("/register");
        } else {
          // Handle an error response (e.g., show an error message)
          toast.error("Failed to verify OTP. Please try again.");
          setOtp(""); // Clear the OTP field
        }
      } catch (error) {
        // Handle any network errors
        toast.error("Please enter the valid OTP.");
        setOtp(""); // Clear the OTP field
      }
    }
  };  
  const handleResendOTPClick = async () => {
    setOtp("");
    setCountdownActive(true); // Disable the button when clicked
    setExpiryTime(300);

    // Get the current date
    const currentDate = new Date();

    // Add 2 minutes
    currentDate.setMinutes(currentDate.getMinutes() + 2.01);

    // Format the date as a string in the desired format
    const expiryDate = currentDate.toISOString();

    try {
      // Send a request to the /api/verify-otp endpoint with a resend flag
      const resendResponse = await axios.put(
        process.env.REACT_APP_API_DOMAIN + "/api/resend-otp",
        {
          mobileNumber,
        }
      );

      if (resendResponse.status === 200) {
        toast.success("OTP resent successfully.");
        // Reset the timer to 90 seconds
        setExpiryTime(300);
      } else {
        toast.error("Failed to resend OTP. Please try again.");
        setCountdownActive(false);
      }
    } catch (error) {
      // Handle any network errors
      console.error("Error:", error);
      toast.error("Please enter the valid OTP.");
      setCountdownActive(false);
    }
  };
  

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}  className={classes['carousel-wrap-otp']}>
              <img src={Img1} className={classes["img-wrapper-carousal"]} />
        </Grid>

        <Grid
          item
          md={6}
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
          className={classes['carousel-wrap-otp']}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
            maxWidth="sm"
            className={classes["grid-space"]}
          >
            <img className={classes["img-wrapper"]} src={ImgLogo} alt="" />

            <Typography
              varieant="h2"
              color="initial"
              fontWeight={900}
              fontSize={24}
              className={classes['heading-text']}
            >
              {props.otpTitle}
            </Typography>

            <Typography variant="p" color="initial" fontSize={14}  className={classes['text-font']}>
              {mobileNumber ? (
                <Typography variant="span" fontWeight={800}></Typography>
              ) : (
                <p>{props.notFoundHeading}</p>
              )}
              <Typography variant="span"> </Typography>
              {mobileNumber ? (
                <Typography>
                  {" "}
                  {props.enterOTP} <b>+{mobileNumber}</b>
                </Typography>
              ) : (
                <p>{props.notFoundHindiHeading}</p>
              )}
            </Typography>

            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
            >
              <OtpInput
                value={otp}
                inputType="tel"
                onChange={setOtp}
                numInputs={4}
                isInputNum={true}
                shouldAutoFocus
                renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    autoComplete="one-time-code"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  />
                )}
                inputStyle={{
                  padding: "15px",
                  borderRadius: "10px",
                  border: "1px solid gray",
                }}
                containerStyle={{ padding: "25px 0px 25px 25px" }}
              />
            </Grid>

            <button
              className={classes["send-button"]}
              onClick={handleSendOTPClick}
            >
              {props.verifyButtonTitle}
            </button>

            <div className={classes["actions"]}>
              <div className={classes["timer"]["text-font"]}>
                <Typography style={{ color: "#949494" }} variant="p">
                  {`${minutes}:${seconds < 10 ? "0" : ""}${seconds} `}
                </Typography>
                <Typography style={{ color: "#949494" }} variant="p">
                  {props.receivedOTP}
                </Typography>
              </div>
              <button
                className={classes["resend"]}
                onClick={handleResendOTPClick}
                disabled={countdownActive}
              >
                {props.resendOTP}
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

OTP.prototype = {
  otpTitle: PropTypes.string.isRequired,
  notFoundHeading: PropTypes.string.isRequired,
  notFoundHeading: PropTypes.string.isRequired,
  enterOTP: PropTypes.string.isRequired,
  receivedOTP: PropTypes.string.isRequired,
  resendOTP: PropTypes.string.isRequired,
  verifyButtonTitle: PropTypes.string.isRequired,
};
