import Img1 from "../../assets/images/3.jpg";
import Img2 from "../../assets/images/4.jpg";
import ImgLogo from "../../assets/images/kishan_logo.svg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import classes from "./Login.module.css";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const LOGIN_API_ENDPOINT = process.env.REACT_APP_API_DOMAIN + "/api/logins";

export const Login = (props) => {
  const [countryCode] = useState("91");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const navigate = useNavigate();
  const [isButtonClicked, setIsButtonClicked] = useState(false); // Add this state variable

  useEffect(() => {
    if (sessionStorage.getItem("currentKey") != 1) {
      switch (sessionStorage.getItem("currentKey")) {
        case "1":
          navigate("/");
          break;
        case "2":
          navigate("/otp");
          break;
        case "3":
          navigate("/register");
          break;
        case "4":
          navigate("/thankyou");
          break;
        default:
          navigate("/");
      }
    }
  }, []);

  const validatePhone = (mobileNumber) => {
    const isValid = /^\d{10}$/.test(mobileNumber);
    return isValid;
  };

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    // Remove non-numeric characters from the input
    const numericValue = inputValue.replace(/\D/g, "");

    // Limit the phone input to 10 digits
    if (numericValue.length <= 10) {
      setPhone(numericValue);
    }
  };

  const handleSendOTPClick = async () => {
    // Check if the button has already been clicked
    if (isButtonClicked) {
      return;
    }

    // Validate phone number and country code
    if (!phone) {
      toast.error("Phone number is required.");
    } else if (!validatePhone(phone)) {
      toast.error("Phone number must be 10 digits.");
    } else {
      // Reset any previous error messages
      setPhoneError("");
      // Set the flag to indicate the button has been clicked
      setIsButtonClicked(true);
      // Call the sendOTP function with the phone number
      await sendOTP(`${countryCode}${phone}`);
    }
  };
  const sendOTP = async (mobileNumber) => {
    try {
      // Define the API endpoint URL
      // Create an object with the data to send in the request body
      const requestData = {
        mobileNumber: mobileNumber,
      };

      // Make the POST request using Axios
      await axios.post(LOGIN_API_ENDPOINT, requestData);
      // Handle the response (you can add logic here based on the response)
      // You can navigate to the OTP page here if needed
      sessionStorage.setItem("mobileNumber", mobileNumber);
      sessionStorage.setItem("currentKey", 2);
      navigate("/otp");
    } catch (error) {
      console.error("Error sending OTP:", error);
      // Handle the error (e.g., show an error message)
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12} className={classes['carousel-wra-img']} >
          <Carousel
            showStatus={false}
            showThumbs={false}
            showArrows={false}
            autoPlay
            showIndicators={false}
            className={classes['carousel-height']}
          >
              <img src={Img1} className={classes["img-wrapper-carousal"]} />
              <img src={Img2} className={classes["img-wrapper-carousal"]} />
          </Carousel>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
          className={classes['carousel-wra-img']}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
            maxWidth="sm"
            className={classes["grid-space"]}
          >
            <img className={classes["img-wrapper"]} src={ImgLogo} alt="" />
            <Typography
              variant="h1"
              color="initial"
              fontWeight="bolder"
              fontSize={25}
              className={classes['heading-text']}
            >
              {props.titleNumber}
            </Typography>

            <Typography variant="p" color="gray" fontSize={18} className={classes['text-font']}>
              We will send you the{" "}
              <Typography variant="span" color="initial" fontWeight={700}>
                4 digit{" "}
              </Typography>
              verification code / हम आपको{" "}
              <Typography variant="span" color="initial" fontWeight={700}>
                4 अंकीय{" "}
              </Typography>
              सत्यापन कोड भेजेंगे
            </Typography>

            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              className={classes["input-side"]}
            >
              <Grid item xs={3}>
                <TextField
                  value="+91"
                  disabled
                  type="text"
                  size="small"
                  InputProps={{
                    sx: {
                      borderRadius: 3,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  value={phone}
                  onChange={handlePhoneChange}
                  type="tel"
                  fullWidth
                  maxLength={10}
                  InputProps={{
                    sx: {
                      borderRadius: 3,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    },
                  }}
                  renderInput={(props) => (
                    <input {...props} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
                  )}
                  size="small"
                />
              </Grid>
            </Grid>
            {phoneError && (
              <p className={classes["error-message"]}>{phoneError}</p>
            )}

            <button
              className={classes["send-button"]}
              onClick={handleSendOTPClick}
              disabled={isButtonClicked} // Disable the button if it has been clicked
            >
              {props.buttonText}
            </button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Login.propTypes = {
  titleNumber: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};
