import { Login } from "./components/Login/Login";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { OTP } from "./components/OTP/OTP";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Register } from "./components/Register/Register";
import { Thankyou } from "./components/Thankyou/Thankyou";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Login
                buttonText="Send OTP / ओटीपी भेजें"
                titleNumber="Enter your mobile number / अपना मोबाइल नंबर दर्ज करें"
              />
            }
          />
          <Route
            path="/otp"
            element={
              <OTP
                otpTitle="OTP Verification / ओटीपी सत्यापन"
                notFoundHeading="Phone number not found."
                notFoundHindiHeading="फ़ोन नंबर नहीं मिला."
                enterOTP="Enter the OTP sent to / भेजा गया ओटीपी दर्ज करें:"
                receivedOTP="Didn’t receive OTP? / क्या ओटीपी नहीं मिला?"
                resendOTP="Resend OTP / ओटीपी पुनः भेजें"
                verifyButtonTitle="Verify / सत्यापित करें"
              />
            }
          />
          <Route path="/register" element={<Register />} />
          <Route path="/thankyou" element={<Thankyou />} />
        </Routes>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      </Router>
    </>
  );
}

export default App;
